<template>
  <b-row>

    <b-col
      v-if="certificateTypes.length"
      md="12"
    >
      <b-card
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="certificateTypes"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.bottom="'Modifier un certificat'"
                v-b-modal.update-modal
                variant="primary"
                size="sm"
                class="btn-icon rounded-circle ml-1"
                @click.prevent="getCertificatType(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.bottom="'Supprimer un certificat'"
                v-b-modal.suppression
                variant="danger"
                size="sm"
                class="btn-icon rounded-circle ml-1"
                @click.prevent="getCertificatType(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>

            </div>
          </template></b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="certificateTypes.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>
    <b-col
      v-else
      md="12"
    >
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-body>
          <b-row>
            <b-col md="6">
              <b-img
                :src="require('@/assets/images/original.png')"
                class="shadow"
                style="height:200px !important"
                alt=""
                srcset=""
              />
            </b-col>
            <b-col
              md="6"
              class="mt-5"
            >
              <span class="text-center">
                Nous n'avons pas trouvé de résultats à afficher
              </span>
            </b-col>
          </b-row>

        </b-card-body>

      </b-card>
    </b-col>
    <b-modal
      id="update-modal"
      ref="my-modal"
      centered
      no-close-on-backdrop
      button-size="sm"
      title="Modifier un certificat"
      :busy="certificateTypeProcess"
      ok-title="Modifier"
      cancel-title="Fermer"
      cancel-variant="secondary"
      ok-variant="success"
      @ok="handleOk"
    >
      <validation-observer ref="certificatValidationRules">
        <b-form @submit.prevent="validationForm">
          <b-row class="mt-2">

            <b-col md="12">

              <b-form-group
                label="Nom du certificat"
                label-for="label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nom du certificat"
                  rules="required"
                >
                  <b-form-input
                    id="label"
                    v-model="form.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nom du certificat"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <label for="description">Description</label>
                <b-form-textarea
                  id="description"
                  v-model="form.description"
                  placeholder="Description"
                  rows="2"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Signataire"
                label-for="signer_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="signataire"
                  rules="required"
                >
                  <v-select
                    id="signer_id"
                    v-model="form.signer_id"
                    label="name"
                    :options="usersSignataires"
                    :reduce="(usersSignataires) => usersSignataires.id"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Signataire"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Type de document"
                label-for="document_type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="type de pièce"
                  rules="required"
                >
                  <v-select
                    id="document_type"
                    v-model="form.document_types"
                    label="label"
                    multiple
                    :options="documentTypes"
                    :reduce="(documentTypes) => documentTypes.id"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Type de document"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

      </validation-observer>
    </b-modal>
    <b-modal
      id="suppression"
      ref="my-modal"
      cancel-title="Non"
      ok-title="Oui"
      title="Suppression de type pièce"
      modal-class="modal-danger"
      ok-variant="danger"
      button-size="sm"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleDeleteOk"
    >
      <form @submit.prevent="handleDeleteSubmitModal">
        <h6>Etes-vous sûr de vouloir supprimer ce type de pièce?</h6>

      </form>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onMounted, reactive } from '@vue/composition-api'
import { required } from '@validations'
import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BFormInput,
  BSpinner, VBTooltip, VBModal, BModal, BFormTextarea, BForm, BImg,
} from 'bootstrap-vue'

// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import useUsers from '@/services/super-admin/userService'
// eslint-disable-next-line import/no-cycle
import useCertificateTypes from '@/services/admin/certificateTypeService'
// eslint-disable-next-line import/no-cycle
import useDocumentTypes from '@/services/admin/documentTypeService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormTextarea,
    BSpinner,
    BModal,
    BForm,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BImg,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,

    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    // eslint-disable-next-line no-undef
    const { getUsersByRoleByMairieId, usersSignataires } = useUsers()
    // eslint-disable-next-line no-unused-vars
    const user = JSON.parse(store.state.authStore.user)
    const {
      storeOrUpdateCertificateType, certificateTypeProcess, certificateTypes, deleteCertificateType, certificateTypeSuccess, loader, listCertificateTypesByMairie,
    } = useCertificateTypes()
    const { listDocumentTypesByMairie, documentTypes } = useDocumentTypes()

    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'name', label: 'Nom', sortable: true },
      { key: 'signataire.name', label: 'Signataire', sortable: true },
      { key: 'actions' },
    ]
    const form = reactive({
      id: null,
      name: '',
      signer_id: null,
      document_types: [],
      description: '',

    })
    const formDesignateAdmin = reactive({
      mairie_id: '',
      user_id: '',
    })
    const resetModal = () => {
      form.id = null
      form.name = null
      form.signer_id = ''
      form.document_types = []
      form.description = ''
    }

    onMounted(async () => {
      await getUsersByRoleByMairieId('SIGNATAIRE')
      await listDocumentTypesByMairie()
      await listCertificateTypesByMairie(user.mairie_id)
      // Set the initial number of items
      totalRows.value = certificateTypes.value.length
    })
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    // Obtenir un certificat
    const getCertificatType = async item => {
      form.id = item.id
      form.name = item.name
      form.signer_id = item.signer_id
      form.document_types = item.document_types_ids
      form.description = item.description
    }
    const updateCertificatType = async () => {
      await storeOrUpdateCertificateType({ ...form })
      console.clear()
    }
    const deleteOneCertificatType = async () => {
      await deleteCertificateType(form.id)
      resetModal()
    }
    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      fields,
      onFiltered,
      form,
      certificateTypeProcess,
      certificateTypeSuccess,
      usersSignataires,
      documentTypes,
      getCertificatType,
      formDesignateAdmin,
      formatter,
      updateCertificatType,
      loader,
      certificateTypes,
      deleteOneCertificatType,
      resetModal,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.validationForm()
    },
    handleDeleteOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleDeleteSubmitModal()
    },

    async handleDeleteSubmitModal() {
      // Exit when the form isn't valid
      await this.deleteOneCertificatType()
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    validationForm() {
      // Exit when the form isn't valid
      this.$refs.certificatValidationRules.validate().then(async success => {
        if (success) {
          // Push the name to submitted names
          this.updateCertificatType()
          if (this.certificateTypeSuccess) {
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn')
            })
          }
        }
      })
    },

  },

}

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
